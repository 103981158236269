import React from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"

import { graphql, Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import Grid from "../components/Grid"
import SafeAreaWrapper from "../components/SafeAreaWrapper"
import Container from "../components/Container"
import Hero from "../components/Hero"
import ArticlePreview from "../components/ArticlePreview"
import { BlueBanner } from "../components/Banner"

import Pagination from "../components/Pagination"

import { GatsbyImage } from "gatsby-plugin-image"

const NewFeaturesPage = ({
	location,
	pageContext,
	pageContext: { currentPage, limit, skip, numPages },
	data: { page, backgroundImage, recentFeatures },
}) => {
	return (
		<Layout translucent={true}>
			<Seo
				title={page.seo.title}
				description={page.seo.description}
				keywords={page.seo.keywords}
			/>
			<Hero
				title="Latest updates and additions"
				subtitle="in Cloud Fleet Manager"
				backgroundImage={backgroundImage}
				translucent={true}
			/>

			<Grid className="mt-spacer-lg mb-spacer-md gap-y-30">
				{recentFeatures.edges.map((edge, index) => {
					return (
						<div className="group hover:cursor-pointer col-span-12 md:col-span-6 xl:col-span-4 flex flex-col">
							<Link to={`/blog/${edge.node.slug}`}>
								<span className="flex-none mr-12">
									<GatsbyImage
										className="mb-20 w-full h-[230px]"
										image={edge.node.teaser_image.imgixImage.gatsbyImageData}
										imgClassName="rounded-default overflow-hidden"
										alt={edge.node.title}
										title={edge.node.title}
									/>
								</span>
								<ArticlePreview
									date={edge.node.published_at}
									title={edge.node.title}
								/>
							</Link>
						</div>
					)
				})}
			</Grid>

			<Container className="mb-spacer-lg flex flex-col items-center">
				<div className="flex flex-row justify-center">
					<Pagination urlPrefix="/new-features" pageContext={pageContext} />
				</div>
			</Container>

			<div className="pb-spacer-xl">
				<SafeAreaWrapper>
					<BlueBanner
						title={page.banner.title}
						subtitle={page.banner.pretext}
						image={
							page.banner.screenshot
								? getImage(page.banner.screenshot.localFile)
								: null
						}
						callToAction="Schedule a demo"
						callToActionUrl="/demo"
					/>
				</SafeAreaWrapper>
			</div>
		</Layout>
	)
}

// ($limit: Int!, $skip: Int!)

export const pageQuery = graphql`
	query ($skip: Int!, $limit: Int!) {
		page: strapiNewFeaturesPage {
			seo {
				title
				description
				keywords
			}
			banner {
				pretext
				title
				screenshot {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 760)
						}
					}
				}
			}
		}

		backgroundImage: file(relativePath: { eq: "features.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
			}
		}

		recentFeatures: allMongodbBloghanseaticsoftArticles(
			filter: { tags: { elemMatch: { name: { in: ["New Feature", "New Module"] } } } }
			sort: { order: DESC, fields: published_at }
			skip: $skip
			limit: $limit
		) {
			edges {
				node {
					title
					published_at(formatString: "MMMM DD, YYYY")
					slug
					teaser_image {
						imgixImage {
							gatsbyImageData(width: 550)
						}
					}
				}
			}
		}
	}
`

export default NewFeaturesPage
